h5 {
    position: relative;
    overflow: hidden;
    height: 50px;
}

h5 a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: -100%;
    animation: slide 15s linear infinite;
}

@keyframes slide {
    0% {
        left: -100%;
    }

    100% {
        left: 100%;
    }
}